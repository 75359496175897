import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ThemeBootstrap from './bootstrap/theme';
import AppProviders from './context/AppProviders';
import clearAllKeys from './features/commun-utils/localStorage-AllKeys';

import numeral from 'numeral';

// on vide le localStorage
clearAllKeys();

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
// got to french babe
numeral.locale('fr');

// Sentry

Sentry.init({
  dsn: 'https://d733150fee6a3e80641a08b3cab4d9d5@o4505726041784320.ingest.sentry.io/4505726049845248',
  integrations: [
    Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: ['localhost', /^https:\/\/app.compas-tis\.fr\/app/],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ThemeBootstrap>
      <Suspense fallback={<div>veuillez patienter...</div>}>
        <BrowserRouter>
          <AppProviders>
            <App />
          </AppProviders>
        </BrowserRouter>
      </Suspense>
    </ThemeBootstrap>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
