/**
 *
 * liste toutes les clés utilisées par l'application pour stocker des objets dans le localStorage
 *
 * Si on développe une nouvelle fonctionnalité qui nécessite une utilisation du localStorage il faut l'ajouter ici.
 * Si on n'utilise plus une clé, mieux vaut la laisser et la tagger en "obsolete"
 *
 * pour chaque clé mettre le fichier JS qui l'utilise
 */

// features/geographies/reducer
const BWEB_UI_TERRITOIRES_REFERENCES_KEY = 'BWEB_UI_TERRITOIRES_REFERENCES_KEY';

// features/geographies/chargerListeTerritoiresModal.js
const BABORDWEB_UI_CHARGER_LISTE_TERRITOIRES_KEY =
  'BABORDWEB_UI_CHARGER_LISTE_TERRITOIRES_KEY';

// AuthenticatedApp.js
const BABORDWEB_UI_NAVBAR_SECONDAIRE_ETAT =
  'BABORDWEB_UI_NAVBAR_SECONDAIRE_ETAT';

const BABORDWEB_UI_CARTOGRAPHIE_KEY = 'BABORDWEB_UI_CARTOGRAPHIE_KEY';

/**
 *
 */
const BWEB_ALL_KEYS = [
  BWEB_UI_TERRITOIRES_REFERENCES_KEY,
  BABORDWEB_UI_CHARGER_LISTE_TERRITOIRES_KEY,
  BABORDWEB_UI_NAVBAR_SECONDAIRE_ETAT,
  BABORDWEB_UI_CARTOGRAPHIE_KEY,
];

/**
 *
 */
function clearAllKeys() {
  if (window.localStorage && BWEB_ALL_KEYS.length > 0) {
    try {
      BWEB_ALL_KEYS.forEach((k) => window.localStorage.removeItem(k));
    } catch (error) {
      console.error(error);
    }
  }
}

export default clearAllKeys;
export {
  BWEB_UI_TERRITOIRES_REFERENCES_KEY,
  BABORDWEB_UI_CHARGER_LISTE_TERRITOIRES_KEY,
  BABORDWEB_UI_NAVBAR_SECONDAIRE_ETAT,
  BABORDWEB_UI_CARTOGRAPHIE_KEY,
};
