import React from 'react';
import {
  ChakraProvider,
  extendTheme,
  defineStyleConfig,
} from '@chakra-ui/react';

import '@fontsource/poppins/index.css';
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import PictoHublotCouleurSVG from 'images/picto_hublot_couleur.svg';
import PictoCorailCouleurSVG from 'images/corail/logo_corail_couleur.svg';

/**
 * Badge
 */
const Badge = defineStyleConfig({
  variants: {
    upper: {
      backgroundColor: 'hublot.jaune',
      color: 'hublot.bleuFonce',
      height: '15px',
      width: '15px',
      fontSize: '9px',
      rounded: 'full',
      position: 'relative',
      top: '-9px',
    },
  },
});

/**
 * Drawer
 */

const Drawer = defineStyleConfig({
  baseStyle: {
    header: {
      backgroundColor: 'hublot.bleuFonce',
      color: 'white',
      backgroundImage:
        process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL'
          ? `url(${PictoCorailCouleurSVG})`
          : `url(${PictoHublotCouleurSVG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -100px top 10px',
      backgroundSize: '300px 300px',
    },
    closeButton: { backgroundColor: 'hublot.bleuElectrique', color: 'white' },
  },
});

/**
 * style des checkbox
 */
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);
const CheckboxBaseStyle = definePartsStyle({
  control: {
    borderColor: 'hublot.grisClair',
    borderRadius: '2px',
    _hover: {
      color: 'white',
      backgroundColor: 'hublot.bleuElectrique',
      borderColor: 'hublot.bleuElectrique',
    },
    _checked: {
      borderColor: 'hublot.jaune',
      backgroundColor: 'hublot.jaune',
      color: 'hublot.bleuFonce',
    },
  },
});
const Checkbox = defineMultiStyleConfig({ baseStyle: CheckboxBaseStyle });

/**
 * les styles des boutons
 */
const Button = defineStyleConfig({
  baseStyle: {
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    paddingBottom: '0',
    paddingInlineStart: '0',
    paddingInlineEnd: '0',
    padding: '2px',
    borderRadius: '8px',
    fontWeight: 'normal',
    letterSpacing: '0',
    maxHeight: '28px',
    _hover: {
      boxShadow: 'none',
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  defaultProps: {
    variant: 'solid',
    size: 'sm',
  },
  variants: {
    solid: {
      padding: '2',
      bg: 'hublot.bleuElectrique',
      color: 'white',
      _hover: {
        boxShadow: 'none',
        fontWeight: 'normal',
        bg: 'hublot.bleuFonce',
      },
      _active: {
        bg: 'hublot.bleuElectrique',
        color: 'white',
      },
    },
    outline: {
      padding: '3px',
      bg: 'white',
      color: 'black',
      border: '2px solid',
      borderColor: 'hublot.jaune',
      boxShadow: 'none',
      _hover: {
        boxShadow: 'none',
        borderColor: 'hublot.jauneFonce',
        backgroundColor: 'hublot.jauneTransparent',
      },
      _active: {
        boxShadow: 'none',
        borderColor: 'hublot.jauneFonce',
        backgroundColor: 'hublot.jaune',
      },
    },

    'outline-blue': {
      padding: '3px',
      bg: 'white',
      color: 'hublot.bleuElectrique',
      border: '2px solid',
      borderColor: 'hublot.bleuElectrique',
      boxShadow: 'none',
      _hover: {
        boxShadow: 'none',
        color: 'hublot.bleuFonce',
        borderColor: 'hublot.bleuFonce',
        backgroundColor: 'hublot.grisClair',
      },
      _active: {
        boxShadow: 'none',
        color: 'hublot.bleuFonce',
        borderColor: 'hublot.bleuFonce',
        backgroundColor: 'hublot.bleuElectrique',
      },
    },

    ghost: {
      color: 'black',
      border: 'none',
      boxShadow: 'none',
      borderRadius: 0,
      _hover: {
        boxShadow: 'none',
        fontWeight: 'normal',
        bg: 'hublot.bleuFonce',
        color: 'white',
      },
      _active: {
        bg: 'hublot.bleuElectrique',
        color: 'white',
      },
    },

    'main-menu': {
      bg: 'transparent',
      color: 'black',
      border: '2px solid',
      borderColor: 'transparent',
      boxShadow: 'none',
      fontSize: '16px',
      fontWeight: 'semibold',
      letterSpacing: '0',
      fontFamily: 'Poppins',
      padding: 2,
      height: '29px',
      _hover: {
        boxShadow: 'none',
        borderColor: 'hublot.bleuElectrique',
      },
      _active: {
        color: 'white',
        backgroundColor: 'hublot.bleuFonce',
        borderColor: 'hublot.bleuFonce',
      },
    },

    'feature-toolbar': {
      bg: 'transparent',
      color: 'black',
      border: '2px solid',
      borderColor: 'transparent',
      boxShadow: 'none',
      fontFamily: 'Arial',
      fontSize: '16px',
      letterSpacing: '0',
      _hover: {
        boxShadow: 'none',
        borderColor: 'hublot.bleuElectrique',
      },
      _active: {
        color: 'white',
        backgroundColor: 'hublot.bleuFonce',
        borderColor: 'hublot.bleuFonce',
      },
    },

    'local-feature-toolbar': {
      bg: 'transparent',
      color: 'black',
      border: '1px solid',
      borderColor: 'transparent',
      boxShadow: 'none',
      fontFamily: 'Arial',
      fontSize: '16px',
      letterSpacing: '0',
      _hover: {
        boxShadow: 'none',
        borderColor: 'hublot.bleuElectrique',
      },
      _active: {
        color: 'white',
        backgroundColor: 'hublot.bleuElectrique',
        borderColor: 'hublot.bleuElectrique',
      },
    },

    'feature-right-sidebar': {
      color: 'black',
      border: '1px solid',
      borderColor: 'transparent',
      boxShadow: 'none',
      fontFamily: 'Arial',
      fontSize: '16px',
      letterSpacing: '0',
      borderRadius: '6px',
      padding: '2',
      lineHeight: '16px',
      maxHeight: '22px',
      _hover: {
        boxShadow: 'none',
        borderColor: 'hublot.bleuElectrique',
        color: 'black',
      },
      _active: {
        color: 'white',
        backgroundColor: 'hublot.bleuElectrique',
        borderColor: 'hublot.bleuElectrique',
      },
    },
  },
});

// Let's say you want to add custom colors
const appTheme = extendTheme({
  fonts: {
    body: 'Arial',
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Arial',
        fontSize: '14px',
        lineHeight: '16px',
      },
      h1: {
        fontFamily: 'Poppins',
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: 'semibold',
        color: 'hublot.bleuElectrique',
      },
      h2: {
        fontFamily: 'Poppins',
        fontSize: '30px',
        lineHeight: '32px',
        fontWeight: 'semibold',
        color: 'hublot.bleuFonce',
      },
      h3: {
        fontFamily: 'Poppins',
        fontSize: '26px',
        lineHeight: '29px',
        fontWeight: 'semibold',
        color: 'hublot.bleuElectrique',
      },
      h4: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '18px',
        fontWeight: 'semibold',
        color: 'hublot.bleuFonce',
      },
    },
  },

  colors: {
    hublot: {
      bleuFonce:
        process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL' ? '#173CAD' : '#000A8A',
      bleuElectrique:
        process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL' ? '#4889D1' : '#1563FF',
      bleuClair:
        process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL' ? '#73AEE3' : '#00BFFF',
      jaune:
        process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL' ? '#CD8F40' : '#FAC900',
      jauneTransparent:
        process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL'
          ? '#CD8F4050'
          : '#FAC90050',
      grisClair: '#E9EDF7',
      violetPale: '#D5D7EC',
      bleuPale: '#C8D7FA',
      beigeRose: '#F7D3C9',
      jauneFonce: '#F9BD00',
    },
    compasLogo: {
      bleuTresFonce: '#00394D',
      bleuFonce: '#007399',
      bleu: '#00ACE5',
      bleuClair: '#19C6FF',
      bleuTresClair: '#C2F0FF',
      grisTresClair: '#F4F6F5',
      grisClair: '#AFC0B6',
      grisFonce: '#8DA698',
      grisTresFonce: '#2D3932',
      jauneTresFonce: '#3A2C03',
      jauneFonce: '#A6800D',
      jaune: '#F2BF27',
      jauneClair: '#FFD042',
      jaunePale: '#FBF3DA',
      noir: '#0D0D0D',
    },
    compas: {
      50: '#f3e3ff',
      100: '#d5b2ff',
      200: '#b780ff',
      300: '#9a4dff',
      400: '#7d1bfe',
      500: '#6302e5',
      600: '#4d00b3',
      700: '#370081',
      800: '#21004f',
      900: '#0c001f',
    },
  },
  components: {
    Button,
    Drawer,
    Checkbox,
    Badge,
    Popover: {
      baseStyle: { zIndex: 100, _focus: { boxShadow: 'none' } },
      defaultProps: { zIndex: 100, _focus: { boxShadow: 'none' } },
    },
    PopoverContent: {
      defaultProps: { zIndex: 100, _focus: { boxShadow: 'none' } },
      baseStyle: { _focus: { boxShadow: 'none' } },
    },
    PopoverBody: {
      defaultProps: { zIndex: 100, _focus: { boxShadow: 'none' } },
      baseStyle: { _focus: { boxShadow: 'none' } },
    },
  },
});

export default function Bootstrap({ children }) {
  return (
    <ChakraProvider theme={appTheme} resetCSS={true}>
      {children}
    </ChakraProvider>
  );
}
