import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Icon,
  Button,
  VStack,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaCheck, FaPlay } from 'react-icons/fa';
import { FaDatabase } from 'react-icons/fa';
import { FaDiagramSuccessor } from 'react-icons/fa6';
import { GiCrafting } from 'react-icons/gi';
import { CiTimer } from 'react-icons/ci';

const Resume = () => {
  return (
    <>
      <Flex
        justifyContent="space-between"
        wrap={'wrap'}
        gap={10}
        marginTop={5}
        px={10}
      >
        <Bloc>
          <BlockHeader
            title="Découverte"
            subTitle="Pour se familiariser avec la plateforme"
          >
            <BlocPicto icon={FaPlay}></BlocPicto>
          </BlockHeader>
          <BlocContent>
            <Prix title="0€ gratuit"></Prix>
            <Button
              as={ReactRouterLink}
              to="/inscription"
              p="4"
              w="full"
              variant={'outline'}
            >
              Commencer
            </Button>
            <BlocDescriptif items="Accédez aux premières données sur l'un de vos territoires;Fonctionnalités : dernières données, classement et évolution;Les Rendez-vous de Hublot" />
            <BlocDuree>Accès illimité</BlocDuree>
          </BlocContent>
        </Bloc>
        <Bloc>
          <BlockHeader
            title="Socle"
            subTitle={'Pour suivre les dynamiques de vos territoires'}
          >
            <BlocPicto icon={FaDatabase}></BlocPicto>
          </BlockHeader>
          <BlocContent>
            <Prix title="A partir de 630€ (*)"></Prix>
            <Button
              as={ReactRouterLink}
              to="http://lecompas.fr/contact/"
              target="_blank"
              p="4"
              w="full"
            >
              Contacter notre équipe
            </Button>

            <BlocDescriptif
              // title={"Enrichissez l'accès Découverte"}
              items="L'ensemble de vos territoires;1 base d'indicateurs;Données infra-communales;L'ensembles des fonctionnalités;Les Instantanés;Les Tableaux de bord"
            />
            <BlocDuree>Abonnement pour 1 an</BlocDuree>
          </BlocContent>
        </Bloc>

        <Bloc highlight>
          <BlockHeader
            title="Premium"
            subTitle={`Progressez vers une observation avancée avec des notes personnalisées et des fonctionnalités exclusives`}
          >
            <BlocPlus title="abonnement plébiscité"></BlocPlus>
            <BlocPicto icon={FaDiagramSuccessor}></BlocPicto>
          </BlockHeader>
          <BlocContent>
            <Prix title="A partir de 2 500€ (*)"></Prix>
            <Button
              as={ReactRouterLink}
              to="http://lecompas.fr/contact/"
              target="_blank"
              p="4"
              w="full"
            >
              Contacter notre équipe
            </Button>
            <BlocDescriptif
              title={`Tout le contenu "Socle" et en plus :`}
              items="Accès à plus de bases d'indicateurs;Veille active;Les Lettres de l’observatoire;Les Bulletins mensuels;Personnalisation avec votre logo;Fonctionnalités exclusives;Données locales"
            />
            <BlocDuree>Abonnement conseillé de 3 ans, possible 1 an</BlocDuree>
          </BlocContent>
        </Bloc>

        <Bloc>
          <BlockHeader
            title="Sur mesure"
            subTitle={'Pour un accompagnement spécifique et personnalisé'}
          >
            <BlocPicto icon={GiCrafting}></BlocPicto>
          </BlockHeader>
          <BlocContent>
            <Prix title="Selon prestation"></Prix>
            <Button
              as={ReactRouterLink}
              to="http://lecompas.fr/contact/"
              target="_blank"
              p="4"
              w="full"
            >
              Contacter notre équipe
            </Button>
            <BlocDescriptif items="Fonctionnalités expertes;Diagnostic de territoire;Etudes spécifiques;Déclinaisation des résultats dans Hublot;Accompagnements par un ou des professionnels du Compas" />
          </BlocContent>
        </Bloc>
      </Flex>
      <Text mt={5}>
        (*) prix hors taxe et par année, variable selon options choisies et
        selon le nombre d'habitants (ou de QPV) de votre périmètre géographique
      </Text>
    </>
  );
};

function BlocDuree({ children }) {
  return (
    <HStack my={3}>
      <Icon as={CiTimer} />
      <Text>{children}</Text>
    </HStack>
  );
}
function BlocDescriptif({ title, items }) {
  return (
    <Box mt={3}>
      {title && (
        <Text fontWeight="bold" mb={3}>
          {title}
        </Text>
      )}
      <VStack align={'start'} ml={2} spacing={3}>
        {items.split(';').map((item, index) => (
          <HStack key={index}>
            <Icon as={FaCheck} />
            <Text>{item}</Text>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
}
function Prix({ title, subTitle }) {
  return (
    <>
      <Text fontSize="xl" fontWeight="bold">
        {title}
      </Text>
      <Text>{subTitle}</Text>
    </>
  );
}

function BlocContent({ children }) {
  return (
    <Box backgroundColor="#F6F5F4" w="full" h="100%">
      <VStack spacing={3} align={'start'} px={3} pt={5} pb={3}>
        {children}
      </VStack>
    </Box>
  );
}

function Bloc({ highlight, children }) {
  return (
    <Box
      borderWidth={highlight ? '2px' : '1px'}
      borderColor={highlight ? 'hublot.bleuElectrique' : 'gray.300'}
      borderRadius="xl"
      overflow="hidden"
      flexGrow={1}
      flexShrink={1}
      flexBasis={0}
      minW={'200px'}
    >
      {children}
    </Box>
  );
}

function BlocPlus({ title }) {
  return (
    <Box
      px={2}
      py={1}
      margin={0}
      backgroundColor={'hublot.bleuElectrique'}
      float={'right'}
      borderRadius={'xl'}
      borderTopRightRadius={'none'}
      borderTopLeftRadius={'none'}
      borderBottomRightRadius={'none'}
    >
      <Text fontSize="xs" color="white">
        {title}
      </Text>
    </Box>
  );
}
function BlockHeader({ title, subTitle, children }) {
  return (
    <Box px={0} h={'160px'}>
      {children}
      <Box px={3} py={4}>
        <Text as="h2" fontSize={'2xl'}>
          {title}
        </Text>
        <Text>{subTitle}</Text>
      </Box>
    </Box>
  );
}
function BlocPicto({ icon }) {
  return <Icon pt={3} pl={3} as={icon} w="32px" h="32px" />;
}

export default Resume;
