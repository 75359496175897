/**
 *
 * Context pour les préférences utilisées dans la session en cours
 * fournit une fonction de récupération des valeurs des préférences selon le slug d'un menu
 *
 */

import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';
import { useSession } from './session-context';

/**
 * le Context
 */
const SessionPrefs = React.createContext();

/**
 * la requête Graphql
 */
const GET_SESSION_PREFS = gql`
  query GET_SESSION_PREFS {
    session {
      preferencesValeurs {
        menu {
          id
          slug
        }
        modalite {
          id
          libelle
          slug
        }
        valeur
        complement
        libelle
      }
    }
  }
`;

/**
 *
 * le provider
 *
 * - en charge de charger les préférences de la session en cours
 * - on le distingue du provider de la session car les valeurs des préférences peuvent changer pendant la session utilisateur
 * - ce qui permet de faire un refresh si besoin
 *
 * @param {*} param0
 * @returns
 */
function SessionPrefsProvider({ ...props }) {
  const { preferences: sessionPreferences } = useSession();

  const {
    loading,
    error,
    data: qryResult,
    refetch,
  } = useQuery(GET_SESSION_PREFS, { nextFetchPolicy: 'cache-only' });

  // informer la console si erreur tout de même
  useEffect(() => {
    if (error) {
      console.error(error.message);
    }
  }, [error]);

  // fournir un helper pour chercher les preférences d'un menu
  const getPreferencesPourMenu = (menuSlug) => {
    // console.log(
    //   `getPreferencesPourMenu(${menuSlug})`,
    //   sessionPreferences,
    //   qryResult
    // );

    if (menuSlug && qryResult?.session?.preferencesValeurs) {
      const preferencesValeurs = qryResult?.session?.preferencesValeurs;
      const pref = _.find(
        sessionPreferences,
        (pref) => pref.menu?.slug === menuSlug,
      );
      if (pref) {
        return _.map(pref.modalitesPossibles, (modalitePossible) => {
          // tableau des valeurs choisies pour le registre
          const valeursChoisies = _.chain(preferencesValeurs)
            .filter(
              (vc) =>
                vc.modalite?.slug === modalitePossible.slug &&
                vc?.menu?.slug === menuSlug,
            )
            .map((p) => ({
              valeur: p.valeur,
              complement: p.complement,
              libelle: p.libelle,
              defaut: false,
            }))
            .value();

          // console.log(
          //   `getPreferencesPourMenu(${menuSlug}) valeursChoisies `,
          //   valeursChoisies,
          //   pref
          // );

          // si la modalité ne possède qu'une valeur, prendre celle choisie
          if (modalitePossible.valeurUnique) {
            if (valeursChoisies?.length > 0) {
              return { ...modalitePossible, valeurs: [...valeursChoisies] };
            } else {
              return {
                ...modalitePossible,
                valeurs: [_.find(modalitePossible.valeurs, (v) => v.defaut)],
              };
            }
          }
          return {
            ...modalitePossible,
            valeurs: _.unionWith(
              valeursChoisies,
              modalitePossible.valeurs,
              (a, b) => a?.valeur === b?.valeur,
            ),
          };
        });
      }
    }
    return [];
  };

  return (
    <SessionPrefs.Provider
      value={{
        preferencesValeurs: qryResult?.session?.preferencesValeurs || [],
        getPreferencesPourMenu,
        refetchPrefs: refetch,
        loading,
        error,
      }}
      {...props}
    />
  );
}

const useSessionPrefs = () => React.useContext(SessionPrefs);

export { useSessionPrefs, SessionPrefsProvider };
