import React, { useReducer } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  gql,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const AuthContext = React.createContext();

/**
 *  identification
 * @param {*} loginId
 * @param {*} loginPwd
 */
const IdentificationQuery = async (loginId, loginPwd) => {
  if (!loginId || !loginPwd) return undefined;

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      errorLink,
      new HttpLink({
        uri: process.env.REACT_APP_BWEB_SERVER,
      }),
    ]),
  });

  return client
    .mutate({
      variables: {
        id: loginId,
        mdp: loginPwd,
      },
      mutation: gql`
        mutation ($id: String!, $mdp: String!) {
          identificationUtilisateur(id: $id, mdp: $mdp) {
            token
            id
            abonnements {
              id
              nom
              dateExpiration
            }
            registres {
              id
              nom
              abonnement {
                nom
                id
              }
            }
          }
        }
      `,
    })
    .then((r) => {
      client.stop();
      return r;
    });
};

/**
 *
 */
const ConnexionQuery = async (identToken, registreId) => {
  if (!registreId) return undefined;

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjllYmJlNTMzLTI4NzYtNGQwYS1hMjI5LTE3OWMwMDFlYjNlZSIsImNvbm5leGlvbklkIjoiY29tcGFzIiwicHJvZmlsIjoiIiwiaWF0IjoxNTkxOTU0MDg5LCJleHAiOjE1OTQ1NDYwODl9.NXWw4GSN0TW1tn7ofRdIoKIifZbtjEu-8SPtFu07XwA";
    // localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: identToken ? `Bearer ${identToken}` : '',
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      authLink,
      errorLink,
      new HttpLink({
        uri: process.env.REACT_APP_BWEB_SERVER,
      }),
    ]),
  });

  return client
    .mutate({
      variables: {
        registreId,
      },
      mutation: gql`
        mutation ($registreId: String!) {
          connexionUtilisateur(registreId: $registreId) {
            token
            id
          }
        }
      `,
    })
    .then((r) => {
      client.stop();
      return r;
    });
};

/**
 *
 * @param {*} props
 */

const BWEB_AUTH_KEY = 'compas-bweb-auth';

function authReducer(state, action) {
  let obj = {};
  switch (action.type) {
    case 'connecte':
      obj = {
        ...state,
        estConnecte: true,
        token: action.token,
        registreId: action.registreId,
        error: undefined,
        tempToken: undefined,
      };
      window.localStorage.setItem(BWEB_AUTH_KEY, JSON.stringify(obj));
      return obj;
    case 'erreur':
      return {
        ...state,
        estIdentifie: false,
        estConnecte: false,
        userId: undefined,
        token: undefined,
        error: action?.error,
      };
    case 'identifie':
      obj = {
        estIdentifie: true,
        estConnecte: false,
        userId: action.userId,
        registreId: undefined,
        token: undefined,
        error: undefined,
        abonnements: action.abonnements,
        registres: action.registres,
        tempToken: action.token,
      };
      window.localStorage.setItem(BWEB_AUTH_KEY, JSON.stringify(obj));
      return obj;
    case 'raz':
    default:
      // window.localStorage.removeItem(BWEB_AUTH_KEY);
      window.localStorage.clear();
      return {
        estIdentifie: false,
        userId: undefined,
        token: undefined,
        error: undefined,
        abonnements: [],
        registres: [],
        tempToken: undefined,
      };
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(
    authReducer,
    {
      estIdentifie: false,
      estConnecte: false,
      userId: undefined,
      registreId: undefined,
      aboId: undefined,
      token: undefined,
      error: undefined,
      abonnements: [],
      registres: [],
      tempToken: undefined,
    },
    (intialState) => {
      const localAuth = window.localStorage.getItem(BWEB_AUTH_KEY);
      // console.log("localAuth ", JSON.parse(localAuth || "{}"));
      return localAuth ? JSON.parse(localAuth) : intialState;
    },
  );

  //
  const identification = async (user, passwd) => {
    try {
      const result = await IdentificationQuery(user, passwd);
      // console.log("identification", result);

      if (result && result.data && result.data.identificationUtilisateur) {
        dispatch({
          type: 'identifie',
          userId: result.data.identificationUtilisateur.id,
          token: result.data.identificationUtilisateur.token,
          abonnements: result.data.identificationUtilisateur.abonnements,
          registres: result.data.identificationUtilisateur.registres,
        });
      } else {
        dispatch({ type: 'erreur', error: "L'identification a échoué." });
      }
    } catch (err) {
      dispatch({ type: 'erreur', error: err.message });
    }
  };

  //
  const connexion = async (registreId) => {
    try {
      const result = await ConnexionQuery(state.tempToken, registreId);
      // console.log("connexion", result);

      if (result && result.data && result.data.connexionUtilisateur) {
        dispatch({
          type: 'connecte',
          registreId,
          token: result.data.connexionUtilisateur.token,
        });
      } else {
        dispatch({ type: 'erreur', error: 'La connexion a échoué.' });
      }
    } catch (err) {
      dispatch({ type: 'erreur', error: err.message });
    }
  };

  //
  const deconnexion = () => {
    dispatch({ type: 'raz' });
  };

  //
  const razErreur = () => {
    dispatch({ type: 'erreur', error: undefined });
  };

  return (
    <AuthContext.Provider
      value={{
        userId: state.userId,
        registreId: state.registreId,
        estIdentifie: state.estIdentifie,
        estConnecte: state.estConnecte,
        token: state.token,
        erreur: state.error,
        abonnements: state.abonnements,
        registres: state.registres,
        identification,
        connexion,
        deconnexion,
        razErreur,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
