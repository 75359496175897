import React from "react";

import { AuthProvider } from "./auth-context";
import { UserIdProvider } from "./user-id-context";

export default function AppProviders({ children }) {
  return (
    <AuthProvider>
      <UserIdProvider>{children}</UserIdProvider>
    </AuthProvider>
  );
}
