import React, { useEffect, useReducer } from 'react';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';

// requête chargement des valeurs de session
const GET_MENUS = gql`
  query GET_MENUS {
    session {
      registre {
        menus {
          id
          titre
          parent {
            id
          }
          parametres
          slug
          type
          ordre
          collections {
            titre
            slug
            type
            descriptif
            ordre
          }
        }
      }
    }
  }
`;

/**
 * transforme la liste des menus provenant de l'api en quelque chose de structuré en arbre
 *
 * la structure ressemble à :
 * { slug, titre, type, ordre, parametres, collections, menus (=sous menus) }
 * @param {*} flatMenus
 * @returns
 */
function flatToTreeMenus(flatMenus) {
  if (!flatMenus) return [];

  // console.log("flatMenus", flatMenus);
  // commencer par prendre que les parents
  const menusParents = _.chain(flatMenus)
    .omitBy((m) => m.parent)
    .sortBy('ordre')
    .value();

  const menusTrees = _.chain(menusParents)
    .map((parent) => {
      return {
        slug: parent.slug,
        titre: parent.titre,
        type: parent.type,
        ordre: parent.ordre,
        parametres: parent.parametres,
        collections: _.orderBy(parent.collections, 'ordre'),
        menus: _.chain(flatMenus)
          .filter((m) => m.parent?.id === parent.id)
          .map((e) => {
            return {
              slug: e.slug,
              titre: e.titre,
              type: e.type,
              ordre: e.ordre,
              parametres: {
                ...e.parametres,
              },
              collections: _.orderBy(e.collections, 'ordre'),
            };
          })
          .orderBy('ordre')
          .value(),
      };
    })
    .value();
  //   console.log("menusTrees", menusTrees);
  return menusTrees;
}

//
const MenuContext = React.createContext();
MenuContext.displayName = 'MenuContext';

//
const initialState = {
  isMenuLoading: false,
  params: [],
  menuSlug: undefined,
  mainMenusRedirections: {},
  menus: [],
};

//
function reducer(state, action) {
  //   console.log("state", state, "action", action);
  switch (action.type) {
    case 'NOUVELLE_ROUTE':
      return {
        ...state,
        params: [],
        mainMenusRedirections: {
          ...state.mainMenusRedirections,
          [`${action.from}`]: action.to,
        },
      };
    case 'CHARGEMENT':
      return { ...state, isMenuLoading: true, menuSlug: action.menuSlug };
    case 'FIN_CHARGEMENT':
      return { ...state, isMenuLoading: false };

    case 'SET_PARAM':
      if (action.mode === 'TOGGLE') {
        const idx = state.params.findIndex(
          (e) => action.getId(e) === action.getId(action.param),
        );
        if (idx < 0) {
          return { ...state, params: [...state.params, action.param] };
        } else {
          state.params.splice(idx, 1);
          return { ...state, params: [...state.params] };
        }
      } else if (action.mode === 'UNIQUE') {
        return { ...state, params: [action.param] };
      } else {
        return state;
      }

    case 'SET_MENUS':
      return { ...state, menus: action.menus };
    default:
      return state;
  }
}

/**
 *
 * @param {*} param0
 * @returns
 */
function MenuProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, error, data } = useQuery(GET_MENUS, {
    nextFetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (data?.session?.registre?.menus) {
      // builder les menus en structure arborescente
      // console.log('GET_MENUS', data?.session?.registre?.menus);
      const menusTree = flatToTreeMenus(data?.session?.registre?.menus);
      // console.log('MenuTree', menusTree);
      dispatch({ type: 'SET_MENUS', menus: menusTree });
    }
  }, [data]);

  const nouvelleRoute = (from, to) =>
    dispatch({ type: 'NOUVELLE_ROUTE', from, to });
  const getMainRedirection = (forSlug) =>
    state.mainMenusRedirections[`${forSlug}`];
  const chargementParams = (menuSlug) =>
    dispatch({ type: 'CHARGEMENT', menuSlug });
  const setParam = (param, mode = 'TOGGLE', getId = (p) => p?.slug || p?.id) =>
    dispatch({ type: 'SET_PARAM', mode, param, getId });
  const finChargementParams = () => dispatch({ type: 'FIN_CHARGEMENT' });

  //
  const getRouteForMenuTypeRecursive = (menu, menuType) => {
    // console.log("getRouteForMenuTypeRecursive", menu);
    if (!menu) return undefined;
    if (menu.type === menuType) {
      return menu.slug;
    }
    if (menu.menus) {
      // console.log("recherche");
      let route = undefined;
      _.each(menu.menus, (m) => {
        let localeRoute = getRouteForMenuTypeRecursive(m, menuType);
        if (localeRoute) {
          route = localeRoute;
        }
      });
      if (route) {
        route = `${menu.slug}/${route}`;
        // console.log("getRouteForMenuTypeRecursive route", menu, route);
        return route;
      }
    }
    return undefined;
  };

  if (loading) return <div>chargement des menus</div>;
  if (error)
    return (
      <div>
        <p style={{ color: 'red' }}>{error.message}</p>
      </div>
    );

  return (
    <MenuContext.Provider
      value={{
        menus: state.menus,
        nouvelleRoute,
        getRouteForMenuType: (menuType) =>
          _.chain(state.menus)
            .map((m) => getRouteForMenuTypeRecursive(m, menuType))
            .filter((r) => r)
            .first()
            .value(),
        getMainRedirection, // renvoie pour un slug d'entrée niveau 0 la derniere route utilisée pour cette base de chemin
        chargementParams,
        finChargementParams,
        isMenuLoading: state.isMenuLoading,
        params: state.params,
        menuSlug: state.menuSlug,
        setParam,
      }}
      {...props}
    ></MenuContext.Provider>
  );
}

/**
 *
 * @returns
 */
function useMenuContext() {
  const context = React.useContext(MenuContext);
  if (context === undefined) {
    throw new Error('useMenuContext must be used within a MenuContext');
  }
  return context;
}

export { MenuProvider, useMenuContext };
