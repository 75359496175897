import React from "react";

import { useAuth } from "./auth-context";

const UserIdContext = React.createContext();

function UserIdProvider(props) {
  const { userId, token, registreId } = useAuth();
  return (
    <UserIdContext.Provider
      value={{ user: { userId, token, registreId } }}
      {...props}
    />
  );
}

const useUserId = () => React.useContext(UserIdContext);

export { useUserId, UserIdProvider };
