import React, { useEffect, useRef } from 'react';
import {
  Flex,
  Box,
  Text,
  Link,
  InputGroup,
  Input,
  InputRightElement,
  Icon,
  Button,
  FormControl,
  FormLabel,
  Select,
  useBreakpointValue,
  Image,
  Center,
} from '@chakra-ui/react';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { RiLoginCircleLine } from 'react-icons/ri';
import { GrFormPrevious } from 'react-icons/gr';

import { useAuth } from 'context/auth-context';
import { useUserId } from 'context/user-id-context';
import logCD94 from './client_cd94/logo_vdm_utile_petit_fond_blanc.gif';
import LogoHublotSVGTailleS from 'images/logo_hublot_couleur_s.png';
import logoCorailTailleS from 'images/corail/logo_corail_couleur_s.png';
import PictoHublotCouleurSVG from 'images/picto_hublot_couleur.svg';
import PictoCorailNoirSVG from 'images/corail/icone_corail_noir.svg';
import { useNavigate } from 'react-router';

import { Header } from 'features-unauthenticated/header';
import _ from 'lodash';

/**
 *
 * Authentification
 *
 */
function Etape1() {
  const [ident, setIdent] = React.useState('');
  const [pwd, setPwd] = React.useState('');
  const [show, setShow] = React.useState(false);
  const handleClickShow = () => setShow(!show);

  const { identification, razErreur } = useAuth();

  const handleChangePwd = (event) => {
    razErreur();
    setPwd(event.target.value?.trim());
  };
  const handleChangeIdent = (event) => {
    razErreur();
    setIdent(event.target.value?.trim());
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (pwd && ident) {
      identification(ident, pwd);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <FormControl isRequired my={5}>
        <FormLabel htmlFor="ident" color="white">
          Identifiant
        </FormLabel>
        <Input
          value={ident}
          onChange={handleChangeIdent}
          pr="4.5rem"
          type={'text'}
          placeholder="insérer votre identifiant"
          id="ident"
          bg={'white'}
        />
      </FormControl>
      <FormControl isRequired my={5}>
        <FormLabel htmlFor="pwd" color="white">
          Mot de passe
        </FormLabel>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="insérer votre mot de passe"
            id="pwd"
            value={pwd}
            onChange={handleChangePwd}
            bg={'white'}
          />
          <InputRightElement width="6rem">
            <Button h="1.75rem" size="sm" onClick={handleClickShow} p={2}>
              {show ? 'Cacher' : 'Montrer'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Flex direction="row" justifyContent="center" alignItems="center" my="5">
        <Button
          variant={'outline-blue'}
          type="submit"
          onClick={onSubmit}
          p={4}
          // isDisabled={!pwd || pwd === '' || !ident || ident === ''}
        >
          S'identifier
        </Button>
      </Flex>
    </form>
  );
}

/**
 *
 * Sélection Abonnement
 */
function Etape2() {
  const selectRef = useRef(null);
  const { registres, connexion, deconnexion } = useAuth();
  useEffect(() => {
    if (registres && registres.length === 1) {
      connexion(registres[0].id);
    }
  }, [registres, connexion]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectRef.current && selectRef.current.value)
      connexion(selectRef.current.value);
  };

  // console.log('registres', registres);

  const registresByAbonnement = _.chain(registres)
    .sortBy('abonnement.nom')
    .groupBy('abonnement.nom')
    .value();
  // console.log('options', registresByAbonnement);
  const options = _.chain(registres)
    .sortBy('abonnement.nom')
    .groupBy('abonnement.nom')
    .keys()
    .map((key) => {
      const registres = registresByAbonnement[key];
      // console.log('key', key, 'registres', registres);
      if (registres.length === 1) {
        return (
          <option value={registres[0].id} key={registres[0].id}>
            {key}
          </option>
        );
      }
      return _.chain(registres)
        .orderBy('nom')
        .map((registre) => (
          <option value={registre.id} key={registre.id}>
            {key} - {registre.nom}
          </option>
        ))
        .value();
    })
    // .toArray()
    .value();
  // console.log('l', options);

  return (
    <form onSubmit={onSubmit}>
      <FormControl isRequired my="10">
        <FormLabel htmlFor="abo" color="hublot.bleuElectrique">
          Sélectionnez votre compte
        </FormLabel>
        <Select
          defaultValue={0}
          ref={selectRef}
          bg={'white'}
          borderWidth={'2px'}
          borderColor="hublot.bleuFonce"
        >
          {options.map((option) => option)}
          {/* {registres.map((registre) => (
            <option value={registre.id} key={registre.id}>
              {registre.abonnement.nom} - {registre.nom}
            </option>
          ))} */}
        </Select>
      </FormControl>
      <Flex justifyContent="space-between" alignItems="center">
        <Link onClick={deconnexion}>
          <Icon as={GrFormPrevious} mr="1" />
          retour
        </Link>
        <Button type="submit" onClick={onSubmit} p={5}>
          choisir cet accès
        </Button>
      </Flex>
    </form>
  );
}

/**
 *
 */
export default function Connexion() {
  const isVisible = useBreakpointValue({ base: false, lg: true });
  const { estIdentifie, estConnecte, erreur } = useAuth();
  const navigate = useNavigate();
  const { user } = useUserId();
  if (user && user.userId && user.token) {
    navigate('/app');
    return null;
  }

  const Chapeau = () => {
    switch (process.env?.REACT_APP_COMPAS_CIBLE) {
      case 'CD94':
        return (
          <Box w="30%" alignSelf={'center'}>
            <img src={logCD94} alt="logo CD94" />
          </Box>
        );
      case 'CORAIL':
        return (
          <Link onClick={() => navigate('..')} marginX={'auto'}>
            <Image
              src={logoCorailTailleS}
              alt="logo hublot"
              w="150px"
              alignSelf={'center'}
            />
          </Link>
        );
      default:
        return (
          <Link onClick={() => navigate('..')} marginX={'auto'}>
            <Image
              src={LogoHublotSVGTailleS}
              alt="logo hublot"
              w="250px"
              alignSelf={'center'}
            />
          </Link>
        );
    }
  };

  return (
    <Flex h="100dvh" flexDirection={'column'}>
      <Header displayAuthAccessCTA={false} displayHublot="true"></Header>
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems={'center'}
        alignSelf="center"
        w={{ base: '100%', md: '80%', lg: '50%' }}
        p={{ base: '20px', md: '10px', lg: '0' }}
      >
        <Flex
          w="full"
          flexDirection="column"
          mt={{ base: '0px', md: '-100px', xl: '-150px' }}
        >
          {isVisible && <Chapeau />}
          {!estIdentifie && !estConnecte ? (
            <Flex
              direction={'column'}
              w="100%"
              backgroundImage={
                process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL'
                  ? PictoCorailNoirSVG
                  : PictoHublotCouleurSVG
              }
              backgroundPosition={'400px 30px'}
              backgroundRepeat={'no-repeat'}
              backgroundColor={
                !estIdentifie && !estConnecte ? `hublot.bleuFonce` : 'white'
              }
              borderRadius={'14px'}
              borderColor={
                !estIdentifie && !estConnecte ? 'white' : `hublot.bleuFonce`
              }
              borderWidth={'2px'}
            >
              <Text as={'h2'} color="white" p={2}>
                <Icon as={RiLoginCircleLine} /> Se connecter
              </Text>

              <Box
                px={{ base: '50px', lg: '20%' }}
                py={{ base: '10px', lg: '30px' }}
              >
                <Etape1 />
              </Box>
            </Flex>
          ) : (
            <Flex
              direction={'column'}
              w="100%"
              backgroundImage={
                process.env.REACT_APP_COMPAS_CIBLE === 'CORAIL'
                  ? PictoCorailNoirSVG
                  : PictoHublotCouleurSVG
              }
              backgroundPosition={'500px 50px'}
              backgroundRepeat={'no-repeat'}
              px={2}
              py={{ base: '10px', lg: '30px' }}
              backgroundColor={
                !estIdentifie && !estConnecte ? `hublot.bleuFonce` : 'white'
              }
              borderRadius={'14px'}
              borderColor={
                !estIdentifie && !estConnecte ? 'white' : `hublot.bleuFonce`
              }
              borderWidth={'2px'}
            >
              <Text as={'h2'} color="hublot.bleuFonce" mb={5}>
                Bienvenue
              </Text>
              <Text>Plusieurs comptes sont rattachés à votre identifiant.</Text>
              <Text>Avec quel compte souhaitez vous vous connectez ?</Text>
              <Box px={{ base: '50px', lg: '20%' }}>
                <Etape2 />
              </Box>
            </Flex>
          )}
        </Flex>
        {erreur && (
          <Alert status="error" mt={1}>
            <AlertIcon />
            <AlertTitle>Erreur de connexion</AlertTitle>
            <AlertDescription>{erreur}</AlertDescription>
          </Alert>
        )}
      </Flex>
    </Flex>
  );
}
