import React from 'react';
import {
  Box,
  Text,
  Icon,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Stack,
  List,
  ListItem,
  ListIcon,
  Button,
  HStack,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  FaArrowRight,
  FaCheck,
  FaDochub,
  FaMailBulk,
  FaPlus,
} from 'react-icons/fa';
import { FaMapLocationDot } from 'react-icons/fa6';

/**
 * Helper function to build table cell.
 *
 * @param {Object} props - The props object.
 * @param {number} props.number - The number of table cells to render.
 * @returns {Array} - An array of table cells.
 */
function TdChecked({ number, addAsterique = false }) {
  const icons = Array.from({ length: number }, (_, index) => (
    <Td key={index} textAlign={'center'}>
      <Icon as={FaCheck} />
      {addAsterique && (
        <Text as="span" ml={2}>
          (*)
        </Text>
      )}
    </Td>
  ));

  return icons;
}

function Titre({ children }) {
  return (
    <Text
      as="h2"
      fontSize={'16px'}
      w={'full'}
      backgroundColor={'hublot.grisClair'}
      borderRadius={'8px'}
      px={2}
    >
      {children}
    </Text>
  );
}

function TableHeader({ title, subtitle }) {
  return (
    <Thead>
      <Tr>
        <Th>
          <Text>{title}</Text>
        </Th>
        <Th fontWeight={800} fontSize={'14px'}>
          Découverte
        </Th>
        <Th fontWeight={800} fontSize={'14px'}>
          Socle
        </Th>
        <Th fontWeight={800} fontSize={'14px'}>
          Premium
        </Th>
      </Tr>
    </Thead>
  );
}

function TableDonneesSociales() {
  return (
    <Stack spacing={3}>
      <Titre>
        Alimentez vos diagnostics et rapports d'activité, réalisez votre ABS,
        pilotez vos politiques publiques etc.
      </Titre>
      <TableContainer>
        <Table variant="simple" size={'sm'}>
          <TableHeader></TableHeader>
          <Tbody>
            <Tr>
              <Td>
                <strong>Base d'indicateurs socles</strong>
                <br />
                <Text fontSize={'12px'}>
                  Contexte général, Peuplement, Niveaux de vie, Logement,
                  Familles, Enfance,
                </Text>
                <Text fontSize={'12px'}>
                  Jeunesse, Seniors, Ménages, Populations fragiles, Acteurs
                  économiques
                </Text>
              </Td>
              <Td>
                premières données
                <br />
                de votre territoire "Le peuplement"
              </Td>
              <TdChecked number={2} />
            </Tr>

            <Tr>
              <Td>
                <strong>Tableaux détaillés du Recensement Insee</strong>
                <br />
                <Text fontSize={'12px'}>
                  accès direct aux différents millésimes
                </Text>
              </Td>
              <Td></Td>
              <TdChecked number={2} />
            </Tr>

            <Tr>
              <Td>
                <strong>Base d'indicateurs Premium</strong>
                <br />
                <Text fontSize={'12px'}>
                  Sécurité, Urbanisme, Enseignement, Habitat Urbanisme,
                </Text>
                <Text fontSize={'12px'}>
                  Environnement, Mobilité, Finances locales, Citoyenneté etc.
                </Text>
              </Td>
              <Td></Td> <Td></Td>
              <TdChecked number={1} />
            </Tr>
            <Tr>
              <Td>
                <strong>Base des Indicateurs Sociaux Départementaux</strong>
              </Td>
              <Td></Td>
              <Td textAlign={'center'}>en option</Td>
              <Td textAlign={'center'}>en option</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function TableDonneesHauban() {
  return (
    <Stack spacing={3}>
      <Titre>
        Données Politique de la Ville : observez les Quartiers prioritaires
      </Titre>
      <TableContainer>
        <Table variant="simple" size={'sm'}>
          <TableHeader></TableHeader>
          <Tbody>
            <Tr>
              <Td>QPV 2015 - données Insee ANCT aux contours exacts </Td>
              <TdChecked number={3} />
            </Tr>

            <Tr>
              <Td>QPV 2024 - données Insee ANCT aux contours exacts</Td>
              <Td></Td>
              <TdChecked number={2} />
            </Tr>

            <Tr>
              <Td>
                QPV 2024 - indicateurs ®Compas géocodés aux contours exacts
              </Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>
            <Tr>
              <Td>
                QPV 2024 - indicateurs ®Compas à l'approche exclusive Iris
              </Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function DetailsGeographies() {
  return (
    <Stack spacing={4}>
      <Titre>Territoires d'études et de comparaison</Titre>
      <Text>
        Les territoires d’études et de comparaison sont définis en fonction de
        votre périmètre géographique.
      </Text>

      <List spacing={3} mx={3}>
        <ListItem>
          <ListIcon as={FaMapLocationDot} color="green.500" />
          Si vous travaillez sur une{' '}
          <Text as="span" fontWeight={'600'}>
            commune
          </Text>{' '}
          : vous aurez accès à votre commune et à ses Iris
        </ListItem>
        <ListItem>
          <ListIcon as={FaMapLocationDot} color="green.500" />
          Si vous travaillez sur un{' '}
          <Text as="span" fontWeight={'600'}>
            EPCI
          </Text>{' '}
          : vous aurez accès à votre EPCI, à ses communes et à leurs Iris
        </ListItem>
        <ListItem>
          <ListIcon as={FaMapLocationDot} color="green.500" />
          Si vous travaillez sur un{' '}
          <Text as="span" fontWeight={'600'}>
            département
          </Text>{' '}
          : vous aurez accès à votre département, ses EPCI, ses communes et
          leurs Iris
        </ListItem>
        <ListItem>
          <ListIcon as={FaMapLocationDot} color="green.500" />
          Si vous travaillez sur des{' '}
          <Text as="span" fontWeight={'600'}>
            quartiers proritaires (QPV)
          </Text>{' '}
          : vous aurez accès à votre EPCI, ses communes, ses QPV et le Hors QPV
        </ListItem>

        <ListItem>
          <ListIcon as={FaPlus} color="hublot.jauneFonce" />
          Il est possible de{' '}
          <Text as="span" fontWeight={600}>
            personnaliser vos périmètres d'études
          </Text>{' '}
          : autres territoires non listés ci dessus, découpages spécifiques
          (basés sur les communes et les Iris), territoires de comparaison
          supplémentaires etc. Hublot offre des fonctions de comparaison pour
          chacun de vos territoires. À ceux-là s’ajoute la référence à la France
          hexagonale.
        </ListItem>
      </List>

      <Button
        as={ReactRouterLink}
        to="http://lecompas.fr/contact/"
        target="_blank"
        w="300px"
      >
        Pour plus de détails, contactez notre équipe
      </Button>
    </Stack>
  );
}

function TableGeographies() {
  return (
    <Stack spacing={3}>
      <Titre>Géographies des territoires d'études</Titre>
      <Text>
        Les territoires d'études et de comparaison sont définis selon votre
        périmètre géographique. Par exemple, si vous travaillez sur un périmètre
        EPCI, nous mettons à votre disposition l'EPCI, les communes de l'EPCI et
        leurs Iris. Pour plus de détails, nous contacter.
      </Text>
      <Text>
        Les territoires d'études sont utilisables dans Hublot comme territoires
        de comparaison en plus de ceux listés ci dessous.
      </Text>
      <TableContainer>
        <Table variant="simple" size={'sm'}>
          <TableHeader></TableHeader>
          <Tbody>
            <Tr>
              <Td>Commune</Td>
              <TdChecked number={4} />
            </Tr>
            <Tr>
              <Td>Infra communal (si disponible)</Td>
              <Td></Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>EPCI, Département ou Région</Td>
              <Td></Td>
              <Td colSpan={3} textAlign={'center'}>
                toutes les communes
              </Td>
            </Tr>
            <Tr>
              <Td>Quartiers prioritaires (QPV)</Td>
              <Td></Td>
              <Td colSpan={3} textAlign="center">
                QPV de l'EPCI, Hors QPV de l'EPCI et EPCI
              </Td>
            </Tr>
            <Tr>
              <Td>Territoires de comparaison complémentaires</Td>
              <Td textAlign={'center'}>
                Département, <br />
                France hexagonale
              </Td>
              <Td colSpan={3} textAlign="center">
                EPCI, Département, Région, France hexagonale
              </Td>
            </Tr>
            <Tr>
              <Td>Vos propres découpages territoriaux</Td>
              <Td></Td>
              <TdChecked number={3} addAsterique={true} />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Text fontSize={'12px'}>
        (*) découpages basés sur des Iris ou des communes; prix variable selon
        le nombre de découpages
      </Text>
    </Stack>
  );
}

function TableFonctionnalites() {
  return (
    <Stack spacing={3}>
      <Titre>Fonctionnalités</Titre>
      <TableContainer>
        <Table variant="simple" size="sm">
          <TableHeader></TableHeader>
          <Tbody>
            <Tr>
              <Td>Dernières données</Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>Evolutions</Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>Cartographies</Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>Classements</Td>
              <Td></Td>
              <TdChecked number={2} />
            </Tr>

            <Tr>
              <Td>Comparaison entre territoires</Td>
              <TdChecked number={3} />
            </Tr>

            <Tr>
              <Td>Exports</Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>Centralisation des documents produits par le Compas</Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>
                Documents produits par le Compas disponibles après la fin de
                l’abonnement
              </Td>
              <TdChecked number={3} />
            </Tr>
            <Tr>
              <Td>Les Rendez-vous de Hublot</Td>
              <TdChecked number={3} />
            </Tr>

            <Tr>
              <Td>
                Les instantanés (commune et Iris)
                <Text fontSize={'xs'} ml={1}>
                  Outil de communication, exportable en document PDF.
                </Text>
                <Text fontSize={'xs'} ml={1}>
                  Retrouvez en infographies, les données essentielles et
                  actualisées en continu.
                </Text>
              </Td>
              <Td></Td>
              <TdChecked number={2} />
            </Tr>
            <Tr>
              <Td>Les Tableaux de bord communaux préformatés</Td>
              <Td></Td>
              <TdChecked number={2} />
            </Tr>

            <Tr>
              <Td>Créer ses propres Tableaux de bord</Td>
              <Td></Td>
              <TdChecked number={2} />
            </Tr>
            <Tr>
              <Td>Les Tableaux de bord préformatés premium</Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>
            <Tr>
              <Td>Intégration de votre logo</Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>

            <Tr>
              <Td>
                Documents clé en main
                <HStack>
                  <Icon as={FaArrowRight} />
                  <Text fontStyle={'italic'}>
                    voir détails dans la section correspondante
                  </Text>
                </HStack>
              </Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>

            <Tr>
              <Td>Fonctionnalités dédiées à l'observation</Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>

            {/* <Tr>
              <Td>Lettres de l’observatoire</Td>
              <Td></Td>
              <Td></Td>

              <Td colSpan={2} textAlign={'center'}>
                chaque trimestre
                <br /> un document PDF de 4 à 8 pages
                <br /> à télécharger dans votre espace dédié
              </Td>
            </Tr>

            <Tr>
              <Td>Bulletin</Td>
              <Td></Td>
              <Td></Td>
              <Td colSpan={2} textAlign={'center'}>
                1 par mois, par mail
              </Td>
            </Tr> */}
            <Tr>
              <Td>
                Gérer soit-même les destinataires des lettres et bulletins
              </Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>
            <Tr>
              <Td>Assistance Compas</Td>
              <Td></Td>
              <Td>standard</Td>
              <Td colSpan={2} textAlign={'center'}>
                prioritaire
              </Td>
            </Tr>
            <Tr>
              <Td>Accès aux nouvelles fonctionnalités</Td>
              <Td></Td>
              <Td>limité</Td>
              <Td colSpan={2} textAlign={'center'}>
                privilégié
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function DetailsObservatoire() {
  return (
    <Stack spacing={3}>
      <Titre>Documents clé en main</Titre>
      <Text>
        Les documents clé en main sont de véritables notes de veille produites
        par le Compas et mises à disposition des abonnés :
      </Text>
      <List mx={10} spacing={4}>
        <ListItem>
          <ListIcon as={FaDochub} color="hublot.jauneFonce" />
          <Text as="span" fontWeight={600}>
            Lettres de l'observatoire
          </Text>{' '}
          : un visuel pédagogique permettant d'éclairer une thématique clé de
          votre territoire et de communiquer auprès de votre réseau de
          partenaires. Chaque trimestre, téléchargez dans votre espace dédié ce
          document PDF de 4 à 8 pages
        </ListItem>
        <ListItem>
          <ListIcon as={FaMailBulk} color="hublot.jauneFonce" />
          <Text as="span" fontWeight={600}>
            Bulletins mensuels{' '}
          </Text>
          : une analyse du Compas sur un enjeu d'actualité nationale avec une
          déclinaison à l'échelle de votre territoire. Recevez ce bulletin par
          mail tous les mois
        </ListItem>
      </List>
      <Text></Text>
    </Stack>
  );
}

function TableBientôtDisponibles() {
  return (
    <Stack spacing={3}>
      <Titre>Bientôt disponible</Titre>
      <TableContainer>
        <Table size="sm">
          <TableHeader></TableHeader>
          <Tbody>
            <Tr>
              <Td>Assistants IA (intelligence artificielle)</Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>

            <Tr>
              <Td>
                Partagez vos constructions et tableaux de bord
                <br /> aux utilisateurs de votre abonnement
              </Td>
              <Td></Td>
              <Td></Td>
              <TdChecked number={1} />
            </Tr>

            <Tr>
              <Td>
                Base d'indicateurs Babord
                <br />
                30 000 indicateurs à portée de clics
              </Td>
              <Td></Td>
              <Td></Td>
              <Td>en option</Td>
            </Tr>

            <Tr>
              <Td>Gestion automone des accès</Td>
              <Td></Td>
              <Td></Td>
              <Td>en option</Td>
            </Tr>
            <Tr>
              <Td>Fonction Fichiers Détails Insee</Td>
              <Td></Td>
              <Td></Td>
              <Td>en option</Td>
            </Tr>

            <Tr>
              <Td>Fonction Octant (bases Mobilités Insee)</Td>
              <Td></Td>
              <Td></Td>
              <Td>en option</Td>
            </Tr>
            <Tr>
              <Td>Fonctions cartographiques avancées</Td>
              <Td></Td>
              <Td></Td>
              <Td>en option</Td>
            </Tr>

            <Tr>
              <Td>Créer ses propres listes d’indicateurs et de territoires</Td>
              <Td></Td>
              <Td></Td>
              <Td>en option</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Text fontSize="lg" mt={10}>
        Et bien d'autres fonctionnalités à découvrir !
      </Text>
    </Stack>
  );
}

export default function Details() {
  return (
    <Box w="full" px={10}>
      <Stack direction="column" gap={20}>
        <DetailsGeographies />
        <TableDonneesSociales></TableDonneesSociales>
        <TableDonneesHauban />
        {/* <TableGeographies /> */}
        <TableFonctionnalites />
        <DetailsObservatoire />
        <TableBientôtDisponibles />
      </Stack>
    </Box>
  );
}
