import React, { Suspense, lazy } from 'react';

import { useUserId } from './context/user-id-context';
import { useAuth } from './context/auth-context';

import { Routes, Route, Link } from 'react-router-dom';

import { ApiClientProvider } from './context/session-context';
import { MenuProvider } from './context/menu-context';

import Landing from './features-unauthenticated/landing';
import Connexion from './features-unauthenticated/connection';

import { SessionPrefsProvider } from './context/session-prefs-context';
import Formulaire from 'features-unauthenticated/formulaire';
import FormulairePolVille from 'features-unauthenticated/formulaire-polville';
import FormulaireCabestan from 'features-unauthenticated/formulaire-cabestan';
import OffresEtTarifs from 'features-unauthenticated/offres-et-tarifs';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

function UserApp() {
  const { user } = useUserId();
  const { deconnexion } = useAuth();
  const onAuthError = () => {
    deconnexion();
  };

  if (user && user.userId && user.token)
    return (
      <Suspense fallback={<div>Chargement...</div>}>
        <ApiClientProvider onAuthError={onAuthError}>
          <SessionPrefsProvider>
            <MenuProvider>
              <AuthenticatedApp />
            </MenuProvider>
          </SessionPrefsProvider>
        </ApiClientProvider>
      </Suspense>
    );
  return <Connexion />;
}

const NotFound = ({ path }) => (
  <>
    <div>Page {path} inexistante.</div>
    <Link to="/app">retour page principale</Link>
  </>
);

export default function App() {
  return (
    <Routes id="premierRouter" style={{ overflow: 'auto' }}>
      <Route path="/" element={<Landing />} />
      <Route path="inscription" element={<Formulaire />} />
      <Route path="inscription/polville" element={<FormulairePolVille />} />
      <Route path="inscription/cabestan" element={<FormulaireCabestan />} />
      <Route path="offres-et-tarifs" element={<OffresEtTarifs />} />
      <Route path="app/*" element={<UserApp />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
