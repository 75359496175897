import React from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Flex,
  Text,
  Box,
  Image,
  Button,
  Link,
  Icon,
  HStack,
} from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react';
import ImgAnalyze from 'images/hublot_illustration_home.svg';
import LandingPageCD94 from './client_cd94';
import LogoHublotSVG from 'images/logo_hublot_couleur.svg';
import { Header } from 'features-unauthenticated/header';
import { LandingPageCorail } from './corail/landing';
import { BiLinkExternal } from 'react-icons/bi';
import PictoGagnezDuTemps from 'images/landing_gagnez_du_temps.svg';
import PictoDonnees from 'images/landing_donnees.svg';
import PictoAnalyse from 'images/landing_analyse.svg';
import PictoDocuments from 'images/landing_documents.svg';

function Hero() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex wrap={{ base: 'wrap', xl: 'nowrap' }} w="full" alignItems={'center'}>
      <Flex
        flexDirection={'column'}
        flexBasis={1}
        flexGrow={1}
        align="flex-start"
        p={{ base: 2, md: 10 }}
      >
        <Image
          alignSelf={{ base: 'self-start' }}
          src={LogoHublotSVG}
          w={'341px'}
          h="132px"
          alt="logo hublot"
          p={0}
          ml={{ base: '-25px', md: '-25px' }}
          mt={{ base: 0, md: '0px', lg: '5px' }}
        />

        <Text
          as={'h2'}
          fontSize={{ base: '2xl' }}
          // color="hublot.bleuElectrique"
          mb={{ base: '5px', lg: '5px' }}
          mt={'-5'}
        >
          {/* Déchiffrer. Diagnostiquer. Décider. */}
          {/* Explorer. Découvrir. Anticiper. */}
          {/* Révéler les territoires */}
          L'outil dédié à la connaissance des territoires
        </Text>

        <Text
          as={'h3'}
          fontWeight={'normal'}
          color="black"
          fontSize={{ base: 'lg', md: 'xl' }}
          my={{ base: '5', md: '5', lg: '10' }}
        >
          {/* L'espace ressources pour disposer à tout moment des données utiles
          pour vos projets : Indicateurs décisionnels, Observatoires
          territoriaux, Analyse des Besoins Sociaux et bien plus */}
          Bases d'indicateurs préparées et fiables. Data-visualisations.
          Rapports prêts à l'emploi. Notes d'actualités. Et bien plus encore.
        </Text>

        <Flex
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={'flex-start'}
          w={isMobile ? 'auto' : 'full'}
          gap={isMobile ? 2 : 5}
        >
          {/* <Button
            as={ReactRouterLink}
            to="app"
            p="4"
            border="1px solid"
            borderColor="hublot.bleuElectrique"
          >
            Accès abonnés
          </Button> */}

          <Button
            as={ReactRouterLink}
            to="inscription"
            p="4"
            variant={'outline'}
            // backgroundColor={'hublot.jauneFonce'}
            // border={'1px solid'}
            // borderColor="hublot.jauneFonce"
            // color="black"
            // _hover={{
            //   color: 'white',
            //   bgColor: 'hublot.bleuFonce',
            //   borderColor: 'hublot.bleuFonce',
            // }}
          >
            Créez gratuitement votre accès Découverte
          </Button>
          {/* <Button
            as={ReactRouterLink}
            to="offres-et-tarifs"
            p="4"
            variant={'outline'}
            // borderColor="hublot.bleuElectrique"
          >
            Contactez notre équipe
          </Button> */}
          {/*<Button
            as={ReactRouterLink}
            to="offres-et-tarifs"
            p="4"
            variant={'outline'}
            borderColor="hublot.bleuElectrique"
          >
            Offres et tarifs
          </Button> */}
        </Flex>
      </Flex>
      <Image
        src={ImgAnalyze}
        alt="logo analyze"
        width={{ base: '200px', lg: '400px' }}
        display={{ base: 'none', lg: 'block' }}
      />
    </Flex>
  );
}

/**
 * nouvelle landing Hublot
 *
 */

function FeatureCardAccordionButton({ isExpanded, Picto, titre }) {
  return (
    <AccordionButton
      // backgroundColor={'hublot.bleuFonce'}
      color="hublot.bleuFonce"
      borderRadius={'8px'}
      _expanded={{ bg: 'hublot.bleuFonce', color: 'white' }}
      _hover={{ bg: 'hublot.bleuFonce', color: 'hublot.jaune' }}
    >
      <HStack
        flex={1}
        textAlign={'left'}
        fontWeight={'bold'}
        fontSize={'16px'}
        lineHeight={'18px'}
      >
        {isExpanded && (
          <Image
            src={Picto}
            // w={'48px'}
            h="36px"
            alt={titre}
          />
        )}
        <Text>{titre}</Text>
      </HStack>
    </AccordionButton>
  );
}

function FeaturesGroupContainer({ isMobile, children }) {
  return !isMobile ? (
    <Accordion allowToggle defaultIndex={0}>
      {children}
    </Accordion>
  ) : (
    <Flex flexDir={'column'} w="full" gap={2}>
      {children}
    </Flex>
  );
}

function FeatureItemContainer({ isMobile, titre, picto, children }) {
  return !isMobile ? (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <h2>
            <FeatureCardAccordionButton
              isExpanded={isExpanded}
              Picto={picto}
              titre={titre}
            />
          </h2>
          <AccordionPanel py={4} fontSize={'16px'}>
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  ) : (
    <Flex flexDir={'column'}>
      <Box
        borderRadius={'8px'}
        backgroundColor="hublot.bleuFonce"
        color="white"
      >
        <HStack
          flex={1}
          textAlign={'left'}
          fontWeight={'bold'}
          fontSize={'16px'}
          p={2}
        >
          <Image src={picto} h="36px" alt={titre} />

          <Text>{titre}</Text>
        </HStack>
      </Box>
      <Box p={4} fontSize={'16px'}>
        {children}
      </Box>
    </Flex>
  );
}

function LandingPageHublot() {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ xl: '1200px' }}
      m="0 auto"
      overflow={'auto'}
    >
      <Header displayAuthAccessCTA={true} />
      <Hero></Hero>
      <Text
        as={'h3'}
        mt={{ base: 38, lg: '5' }}
        alignSelf={'flex-start'}
        px={{ base: 2, md: 10 }}
      >
        Hublot vous ouvre l'expertise du Compas
      </Text>

      <Flex
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        justify={'space-between'}
        w="full"
        my={{ base: 10, lg: 0 }}
      >
        <Box
          width={{ base: '480px', md: '640px' }}
          height={'360px'}
          mx={{ base: 0, md: 10 }}
        >
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/6tQeNWhBFaw?si=nNItmuM7HSBsNEZY&autoplay=1&mute=0&loop=1&vq=hd1080&hd=1&controls=1&playlist=6tQeNWhBFaw`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
            title="Le Compas présente Hublot"
          />
        </Box>
        <Box
          w={{ base: 'auto', lg: '40%' }}
          mx={{ base: 3, lg: 0 }}
          my={{ base: 20, lg: 0 }}
          alignSelf={{ base: 'center', lg: 'flex-start' }}
        >
          <FeaturesGroupContainer isMobile={isMobile}>
            <FeatureItemContainer
              isMobile={isMobile}
              titre="Gagnez du temps : les données de votre collectivité sont ici !"
              picto={PictoGagnezDuTemps}
            >
              Grâce à nos 30 ans d’expérience à vos côtés et à notre maîtrise du
              potentiel des données ouvertes, nous avons élaboré des bases
              d’indicateurs adaptées à vos usages : pour alimenter vos
              diagnostics, valoriser les atouts de vos territoires et vous
              faciliter l’évaluation de l’action publique locale.
            </FeatureItemContainer>
            <FeatureItemContainer
              isMobile={isMobile}
              titre="Restez en veille, bénéficiez de notes personnalisées sur les dynamiques de vos territoires"
              picto={PictoDonnees}
            >
              Observez les spécificités de vos territoires et leurs dynamiques
              en profitant des fonctions de datavisualisation (suivi dans le
              temps, cartographie, infographie) et de comparaison. Ajoutez vos
              commentaires, exportez vos sélections et diffusez-les.
            </FeatureItemContainer>
            <FeatureItemContainer
              isMobile={isMobile}
              titre="Consolidez vos analyses, contextualisez vos projets, aidez à la décision "
              picto={PictoAnalyse}
            >
              Nous avons retraité toutes les données pour qu'elles soient
              porteuses de sens et réellement utiles aux diagnostics. Sont
              intégrées : des notes de décryptage et des clés de lecture, des
              limites méthodologiques, le secret statistique, les modalités de
              calcul et/ou de croisements, etc. Repérez les nouveautés grâce à
              l’onglet actualités.
            </FeatureItemContainer>
            <FeatureItemContainer
              isMobile={isMobile}
              titre="Disposez de documents clés en main"
              picto={PictoDocuments}
            >
              Hublot vous facilite l'accès aux données grâce à des tableaux de
              bord thématiques prêts à l'emploi, constamment à jour, et
              éditables pour tous vos territoires (quartiers, communes, EPCI,
              territoires d’action, ...). Bénéficiez également de notes rédigées
              par le Compas pour éclairer vos territoires :{' '}
              <em>Bulletins de veille</em> sur des enjeux d'actualité,{' '}
              <em>Lettres de l'Observatoire</em> sur des thématiques clés.
            </FeatureItemContainer>
          </FeaturesGroupContainer>
        </Box>
      </Flex>

      {/* <Button
        as={ReactRouterLink}
        p="6"
        mx={{ base: 2, md: 10 }}
        mt={4}
        bg={'hublot.jauneFonce'}
        color="black"
        _hover={{ color: 'white', bgColor: 'hublot.bleuFonce' }}
        alignSelf={'center'}
        to="mailto:contact@compas-tis.com"
        fontSize={'16px'}
      >
        Demander un devis et s'abonner
      </Button> */}

      <Flex
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        p={{ base: 1, md: 3 }}
        mt={6}
        borderTopWidth={'2px'}
        borderTopColor={'hublot.grisClair'}
      >
        <Link isExternal={true} href="http://www.lecompas.fr/mentions-legales/">
          Mentions légales
        </Link>
        <Text>Tous droits réservés © Le Compas 2024</Text>
      </Flex>
    </Flex>
  );
}

export default function LandingPage() {
  if (process.env.REACT_APP_COMPAS_CIBLE) {
    switch (process.env.REACT_APP_COMPAS_CIBLE) {
      case 'CD94':
        return <LandingPageCD94 />;
      case 'CORAIL':
        return <LandingPageCorail />;
      default:
        return <LandingPageHublot />;
    }
  } else {
    return <LandingPageHublot />;
  }
}
