import { Flex } from '@chakra-ui/react';

export default function FormulaireCabestan() {
  return (
    <Flex h="100dvh" flexDirection={'column'}>
      <iframe
        width={'100%'}
        height={'100%'}
        title="Formulaire"
        data-w-type="embedded"
        src="https://3p1p.mj.am/wgt/3p1p/k6p/form?c=798b5979"
      ></iframe>
    </Flex>
  );
}
